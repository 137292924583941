<!--
 * @Description: 邮件
 * @Version: 1.0
 * @Autor: ziwei
 * @Date: 2021-08-09 17:01:20
 * @LastEditors: ziwei
 * @LastEditTime: 2021-08-19 14:13:08
-->
<template>
  <div>

    <div class="w ww youjian">
      <div class="bread">您当前位置 > <a>首页</a> > <a>邮件订阅</a></div>
      <div class="youjian_ban"><img src="@/assets/img/67.jpg"
             width="100%" /></div>
      <div class="youjian_con">
        <div class="pc_fl youjian_con_txt">
          <h3>为什么订购欧赛思？</h3>
          <p><img src="@/assets/img/69.png" />最新鲜的企业资讯</p>
          <p><img src="@/assets/img/70.png" />最即时的优惠讯息</p>
          <p><img src="@/assets/img/71.png" />最快速的产品速递</p>
          <p><img src="@/assets/img/72.png" />最专业的技术宝典</p>
        </div>
        <div class="pc_fr youjian_con_img"><img src="@/assets/img/68.jpg"
               width="100%" /></div>
        <div class="clear"></div>
        <div class="shenqing">
          <h3>填写申请</h3>
          <form>
            <div class="pc_fl shenqing_1"><span>姓名：</span>
              <input name=""
                     type="text"
                     v-model="fmData.name"
                     placeholder="请输入您的姓名" />
            </div>
            <div class="pc_fr shenqing_1"><span><i>*</i>邮箱：</span>
              <input name=""
                     v-model="fmData.email"
                     type="text"
                     placeholder="请输入您的邮箱" />
            </div>
            <div class="clear"></div>
            <!-- <div class="shenqing_2">
              <span><i>*</i>选择邮件偏好：</span>
              <label><input name="hao"
                       type="radio"
                       value=""
                       checked="checked" />选择邮件偏好</label>
              <label><input name="hao"
                       type="radio"
                       value="" />选择邮件偏好</label>
            </div> -->
            <div class="shenqing_3">
              <span>建议：</span>
              <textarea name=""
                        v-model="fmData.advice"
                        cols=""
                        rows=""
                        placeholder="您是否还有其他偏好需求或意见建议？"></textarea>
            </div>
            <div class="shenqing_4">注：请只填写文字、英文以及标点，请勿填写任何图标，否则无法收到您的信息。</div>
            <div class="shenqing_5"><button @click="sendEmail">提交申请</button></div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Com } from '@/api/index';
import { showLayer, validateEmail } from '@/utils/com';

export default {
  name: 'Email',
  data () {
    return {
      fmData: {
        name: "",
        advice: '',
        email: '',
      }
    }
  },
  methods: {
    //邮件订阅
    async sendEmail () {
      let emRes = validateEmail(this.fmData.email);
      if (emRes)
      {
        showLayer('toast', emRes);
        return
      }
      let result = await Com.sendEmail(this.fmData);
      result && showLayer('success', '订阅成功！');
      this.fmData = {
        name: "",
        advice: '',
        email: '',
      }
    }
  }
}
</script>

<style>
</style>